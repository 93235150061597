








































































import alertS from '@/services/AlertService/AlertService';
import { getTypes } from '@/services/constants';
import { mapGetters } from 'vuex';
import rService from '../services/RequestService/RequestServiceSetup';

export default {
  name: 'MyEntries',
  props: {},
  data() {
    return {
      path: 'ds',
      entryPage: false,
      entry: '',
      oldPage: {},
      pagination: {
        itemsPerPage: 15,
        rowsPerPage: 15,
        pageSize: 10,
        page: 1,
        sort: { date: -1 },
      },
      entries: 0,
      page: 0,
      loading: false,
      values: [],
      totalPages: 0,
      itemsPerPage: 15,
    };
  },
  components: {
    search: () => import('@/components/CheckAccount/SearchDehashed.vue'),
  },
  watch: {
    '$route.name'(newValue) {
      console.log('Update name', newValue);
      this.pagination.itemsPerPage = this.itemsPerPage;
      if (!this.userInfo.unique_id) {
        return;
      }
      this.init();
    },
    entry(newValue) {
      this.searchEntries();
    },
    pagination: {
      handler(newVal) {
        this.itemsPerPage = newVal.itemsPerPage;
        let sort: any = '';
        if (newVal.sortBy && newVal.sortBy.length) {
          sort = {};
          sort[newVal.sortBy[0]] = newVal.sortDesc[0] ? -1 : 1;
        }
        if (this.entries > 0) this.myEntries(newVal.page, sort);
      },
      deep: true,
    },
    'userInfo.unique_id'() {
      this.init();
    },
  },
  methods: {
    linkPage(item: any) {
      if (this.$route.name === 'myEntriesDehashed') {
        return { name: 'dehashedSearchEntry', params: { query: item.query, pages: item.page.join(',') } };
      } else {
        return { name: 'experimentalSearchEntry', params: { entry: item.query, type: item.type, wildcard: item.wildcard ? '1' : '0' } };
      }
    },
    getQueryParams() {
      return {
        page: this.pagination.page,
      };
    },
    searchEntries() {
      /* Define grouped */
      let page = 1;
      if (this.entryPage) {
        this.entryPage = false;
        page = this.pagination.page;
      }
      this.myEntries(page);
    },
    changePage(newPage) {},
    processDescription() {},
    async myEntries(page, sort?) {
      // this.values = [];
      if (this.userInfo && this.userInfo.unique_id) {
        this.loading = true;
        const entryEncode = encodeURIComponent(this.entry);
        const newJson = {
          limit: this.itemsPerPage,
          page: page || 1,
          query: this.entry,
          sort: sort || { date: -1 },
        };
        if (JSON.stringify(this.oldPage) === JSON.stringify(newJson)) {
          this.loading = false;
          return;
        } else {
          this.oldPage = newJson;
        }

        const url = 'user_searches/' + this.$route.meta.path;
        console.log('URL', url);
        const resEntries = await rService.post(url, newJson, true);
        const entries = resEntries.docs;
        this.totalPages = resEntries.totalPages;
        this.entries = resEntries.totalDocs ? parseInt(resEntries.totalDocs) : 0;
        if (entries && !resEntries.error) {
          this.values = entries;
          this.loading = false;
          this.$router.push({ path: `/${this.$route.name}/${this.entry}`, query: this.getQueryParams() }).catch();
        }
        this.loading = false;
      }
    },
    timeAgo(date: string) {
      const utcMoment = this.$moment.utc();
      const dateConverted = this.$moment(date);
      const sTime = dateConverted.from(utcMoment);
      return sTime;
    },
    formatDate(date) {
      return this.$moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    translate(item) {
      return this.$vuetify.lang.t('$vuetify.Home.' + item);
    },
    getType(type) {
      const el = this.getTypes.find(function (el) {
        return el.value == type;
      });
      if (el) return el.name;
    },
    init() {
      this.oldPage = {};
      this.values = [];
      if (this.userInfo.plan !== 'pro') {
        alertS.errorRole('Only Pro Users have access to this feature', () => {
          this.$router.push({ name: 'pricing' }).catch();
        });
      }
      const params = this.$route.params;
      if (params && params.entry) {
        this.entry = params.entry;
        this.entryPage = true;
      }
      const queryParams = this.$route.query;
      if (queryParams) {
        let { page } = queryParams;
        if (page) {
          page = parseInt(page);
          if (isNaN(page)) page = 1;
          this.pagination.page = page;
        }
      }

      console.log('My Entries');
      this.myEntries();
      // this.myEntries(0);
    },
  },
  computed: {
    getTypes() {
      return getTypes(this.translate);
    },
    ...mapGetters(['userInfo']),
    headers() {
      if (this.$route && this.$route.name === 'myEntriesDehashed') {
        return [
          { text: 'Query', value: 'query', width: '90px', sortable: true },
          { text: 'Total Results', value: 'total', width: '100px', sortable: true },
          { text: 'Page', value: 'page', width: '300px', sortable: true },
          { text: 'Date', value: 'date', width: '100px', sortable: true },
        ];
      } else {
        return [
          { text: 'Query', value: 'query', width: '90px', sortable: true },
          { text: 'Type', value: 'type', width: '90px', sortable: true },
          { text: 'Total Results', value: 'total', width: '100px', sortable: true },
          { text: 'Wildcard', value: 'wildcard', width: '300px', sortable: true },
          { text: 'Date', value: 'date', width: '100px', sortable: true },
        ];
      }
    },
  },
  created() {
    this.pagination.itemsPerPage = this.itemsPerPage;
    if (!this.userInfo.unique_id) {
      return;
    }
    this.init();
  },
};
